@import '../../../sass/variables';

.projectAllTable {
  &__wr {
    position: relative;
  }
  
  &__preloader-wr {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: calc(100vh - 238px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    backdrop-filter: blur(1px);
    
    background: rgba(255, 255, 255, 0.9);
    
    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      width: 42px;
      height: 42px;
      
      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 42px;
        height: 42px;
        border-width: 3px;
      }
    }
  }
};
