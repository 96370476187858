@import '../../../sass/mixins';


.common_preloader_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background: #ffffff;
    opacity: .95;
    flex-direction: column;
    @include size(100vw, 100vh);
    @include flex(center, center);
    .common_perloader {
        background-image: url('../../../img/login_logo.png');
        z-index: 1000;
        animation: preloder_jump 1.5s ease-in-out infinite;
        position: relative;
        @include size(100px, 59px); }
    .shadow {
        left: 0;
        opacity: .7;
        bottom: -8px;
        background: #000000;
        border-radius: 100px/5px;
        animation: shadow_grow 1.5s ease-in-out infinite;
        @include size(100px, 5px); } }
